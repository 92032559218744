/**
 * Copyright 2021 Rock Bottom Software. All Rights Reserved.
 */
 .page-transition {
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: fadeIn;
  animation-duration: 750ms;
}
