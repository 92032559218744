.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 20px;
}

.swiper-pagination-bullet-active-custom {
  width: 10px !important;
  height: 10px !important;
  margin: 1px 3px -1px !important;
  opacity: 1 !important;
  background: #42a5f5 !important;
}

.swiper-pagination-bullet-custom {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  display: inline-block;
  border-radius: 50%;
  background: #787878;
  opacity: 0.5;
}
